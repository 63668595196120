import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { generateSearchQuery } from '../../core/helpers/params-generator';
import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { HousekeeperDashboard } from '../../models';
import {
  HousekeeperDashboardService,
  HousekeeperSortablesService,
} from '../../services';
import { ExportService } from '../../services/export.service';
import { ReservationDetailsService } from '../../services/reservation-details.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class HousekeeperDashboardStoreEffects {
  constructor(
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private exportService: ExportService,
    private notifications: NotificationService,
    private dashboardService: HousekeeperDashboardService,
    private sortablesService: HousekeeperSortablesService,
    private reservationDetailsService: ReservationDetailsService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ searchParams, properties, dateFrom, dateTo }) =>
        this.dashboardService
          .load({
            ...searchParams,
            property_id: properties,
            date_from: dateFrom,
            date_to: dateTo,
          })
          .pipe(
            map(({ data }: IResponseSuccess<HousekeeperDashboard>) => {
              return fromActions.loadSuccess({
                dashboard: data,
                dateFrom,
                dateTo,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.loadFailure(error));
            }),
          ),
      ),
    ),
  );

  export$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.exportRequest),
        tap(
          ({
            exportActionType,
            selectedColumns,
            params: { dateFrom, dateTo, properties, searchParams },
          }) =>
            this.exportService.export(
              `properties/housekeeper/dashboard/print?${generateSearchQuery({
                ...searchParams,
                property_id: properties,
                date_from: dateFrom,
                date_to: dateTo,
                columns: selectedColumns,
                orientation: 'landscape',
              })}`,
              'pdf',
              exportActionType,
            ),
        ),
      ),
    { dispatch: false },
  );

  setRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setRecordRequest),
      switchMap(({ request }) =>
        this.dashboardService.setRecord(request).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.setRecordSuccess({ request });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setRecordFailure(error));
          }),
        ),
      ),
    ),
  );

  setNotesForHousekeeper$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setNotesForHousekeeperRequest),
      mergeMap(({ type, reservation, ...params }) =>
        this.reservationDetailsService
          .updateNotes(
            reservation.id,
            'housekeeper',
            reservation.housekeeper_notes,
            reservation.reservation_accommodation_id,
          )
          .pipe(
            map(() => {
              this.notifications.success('notifications.generic_success');
              return fromActions.setNotesForHousekeeperSuccess({
                reservation,
                ...params,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.setNotesForHousekeeperFailure(error));
            }),
          ),
      ),
    ),
  );

  setRecordUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setRecordUsersRequest),
      switchMap(({ request }) =>
        this.dashboardService.setRecordUsers(request).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.setRecordUsersSuccess({ request });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setRecordUsersFailure(error));
          }),
        ),
      ),
    ),
  );

  sortRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.sortRecordsRequest),
      switchMap(({ propertiesIds, groupId, records, date }) =>
        this.sortablesService
          .sort(
            propertiesIds,
            records.map(({ related }, order) => ({
              ...related,
              order,
            })),
            date,
          )
          .pipe(
            map(() => {
              this.notifications.success('notifications.generic_success');
              return fromActions.sortRecordsSuccess({
                groupId,
                records,
                date,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.sortRecordsFailure(error));
            }),
          ),
      ),
    ),
  );
}
