import { HousekeeperDashboard } from '../../models';

export interface State {
  dashboard: HousekeeperDashboard;
  dates: string[];
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  dashboard: null,
  dates: null,
  isLoading: false,
  error: null,
};
