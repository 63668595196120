import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>('housekeeperDashboard');

export const selectDasboard = createSelector(
  selectState,
  (state) => state.dashboard,
);

export const selectDates = createSelector(selectState, (state) => state.dates);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectError = createSelector(selectState, (state) => state.error);
