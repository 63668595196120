import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HousekeeperDashboardStoreEffects } from './effects';
import { housekeeperDashboardReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('housekeeperDashboard', housekeeperDashboardReducer),
    EffectsModule.forFeature([HousekeeperDashboardStoreEffects]),
  ],
})
export class HousekeeperDashboardStoreModule {}
