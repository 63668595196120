import { createAction, props } from '@ngrx/store';

import {
  ExportActionType,
  HousekeeperDashboard,
  HousekeeperDashboardRecord,
  HousekeeperDashboardRecordReservation,
  SetHousekeeperRecordUsersRequest,
} from '../../models';
import { LoadHousekeeperDashboardRequest } from '../../models/requests/load-housekeeper-dashboard.request';
import { SetHousekeeperRecordRequest } from '../../models/requests/set-housekeeper-record.request';

export const loadRequest = createAction(
  '[Housekeeper Dashboard] Load Request',
  props<LoadHousekeeperDashboardRequest>(),
);
export const loadSuccess = createAction(
  '[Housekeeper Dashboard] Load Success',
  props<{
    dashboard: HousekeeperDashboard;
    dateFrom: string;
    dateTo: string;
  }>(),
);
export const loadFailure = createAction(
  '[Housekeeper Dashboard] Load Failure',
  props<{ error: any }>(),
);

export const exportRequest = createAction(
  '[Housekeeper Dashboard] Export Request',
  props<{
    exportActionType: ExportActionType;
    selectedColumns: string[];
    params: LoadHousekeeperDashboardRequest;
  }>(),
);

export const setRecordRequest = createAction(
  '[Housekeeper Dashboard] Set Record Request',
  props<{
    request: SetHousekeeperRecordRequest;
  }>(),
);
export const setRecordSuccess = createAction(
  '[Housekeeper Dashboard] Set Record Success',
  props<{
    request: SetHousekeeperRecordRequest;
  }>(),
);
export const setRecordFailure = createAction(
  '[Housekeeper Dashboard] Set Record Failure',
  props<{ error: any }>(),
);

export const setNotesForHousekeeperRequest = createAction(
  '[Housekeeper Dashboard] Set Notes For Housekeeper Request',
  props<{
    related_type: string;
    related_id: number;
    group_id: number;
    date: string;
    reservation: HousekeeperDashboardRecordReservation;
  }>(),
);
export const setNotesForHousekeeperSuccess = createAction(
  '[Housekeeper Dashboard] Set Notes For Housekeeper Success',
  props<{
    related_type: string;
    related_id: number;
    group_id: number;
    date: string;
    reservation: HousekeeperDashboardRecordReservation;
  }>(),
);
export const setNotesForHousekeeperFailure = createAction(
  '[Housekeeper Dashboard] Set Notes For Housekeeper Failure',
  props<{ error: any }>(),
);

export const setRecordUsersRequest = createAction(
  '[Housekeeper Dashboard] Set Record Users Request',
  props<{
    request: SetHousekeeperRecordUsersRequest;
  }>(),
);
export const setRecordUsersSuccess = createAction(
  '[Housekeeper Dashboard] Set Record Users Success',
  props<{
    request: SetHousekeeperRecordUsersRequest;
  }>(),
);
export const setRecordUsersFailure = createAction(
  '[Housekeeper Dashboard] Set Record Users Failure',
  props<{ error: any }>(),
);

export const sortRecordsRequest = createAction(
  '[Housekeeper Dashboard] Sort Records Request',
  props<{
    date: string;
    propertiesIds: number[];
    groupId: number;
    records: HousekeeperDashboardRecord[];
  }>(),
);
export const sortRecordsSuccess = createAction(
  '[Housekeeper Dashboard] Sort Records Success',
  props<{
    date: string;
    groupId: number;
    records: HousekeeperDashboardRecord[];
  }>(),
);
export const sortRecordsFailure = createAction(
  '[Housekeeper Dashboard] Sort Records Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Housekeeper Dashboard] Reset State');
