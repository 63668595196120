import { Action, createReducer, on } from '@ngrx/store';
import { mapValues } from 'lodash';

import { getDatesFromInterval } from '../../helpers';
import { HousekeeperDashboardRecord } from '../../models';

import * as fromActions from './actions';
import { initialState, State } from './state';

export const reducer = createReducer(
  initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { dashboard, dateFrom, dateTo }) => ({
    ...state,
    dashboard,
    dates: getDatesFromInterval(dateFrom, dateTo),
    isLoading: false,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.setRecordSuccess, (state, { request }) => {
    return mapGroupRecords(state, request.date, request.group_id, (records) =>
      records.map((record) => {
        if (
          record.related.type === request.related_type &&
          record.related.id === request.related_id
        ) {
          const { housekeeper_scenario_id, ...recordParams } = request.record;

          return {
            ...record,
            ...recordParams,
            housekeeper_scenario: request.record.hasOwnProperty(
              'housekeeper_scenario_id',
            )
              ? { id: housekeeper_scenario_id }
              : record.housekeeper_scenario,
          };
        }

        return record;
      }),
    );
  }),

  on(
    fromActions.setNotesForHousekeeperSuccess,
    (
      state,
      {
        date,
        group_id,
        related_type,
        related_id,
        reservation: { roomreservation_id, housekeeper_notes },
      },
    ) => {
      return mapGroupRecords(state, date, group_id, (records) =>
        records.map((record) => {
          if (
            record.related.type === related_type &&
            record.related.id === related_id
          ) {
            return {
              ...record,
              reservations: mapValues(record.reservations, (reservation) => {
                if (reservation?.roomreservation_id === roomreservation_id) {
                  return { ...reservation, housekeeper_notes };
                }

                return reservation;
              }),
            };
          }

          return record;
        }),
      );
    },
  ),

  on(fromActions.setRecordUsersSuccess, (state, { request }) => {
    return mapGroupRecords(state, request.date, request.group_id, (records) => {
      const saro = records.map((record) => {
        if (
          record.related.type === request.related_type &&
          record.related.id === request.related_id
        ) {
          return {
            ...record,
            housekeeper_users: request.housekeepers.map((id) => ({
              id,
              name: null,
            })),
          };
        }

        return record;
      });

      return saro;
    });
  }),

  on(fromActions.sortRecordsSuccess, (state, { groupId, records, date }) => {
    return mapGroupRecords(state, date, groupId, () => records);
  }),

  on(fromActions.resetState, () => initialState),
);

export function housekeeperDashboardReducer(
  state: State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function mapGroupRecords(
  state: State,
  date: string,
  groupId: number,
  mapper: (
    records: HousekeeperDashboardRecord[],
  ) => HousekeeperDashboardRecord[],
): State {
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      dates: {
        ...state.dashboard.dates,
        [date]: {
          ...state.dashboard.dates[date],
          groups: state.dashboard.dates[date].groups.map((group) => {
            if (+groupId !== +group.group_id) {
              return group;
            }

            return { ...group, records: mapper(group.records) };
          }),
        },
      },
    },
  };
}
